import * as React from "react";
import OAuth from '../components/OAuth';
import http from '../utils/http'
import { useNavigate } from "react-router-dom";

const Signup = () => {
    const navigate = useNavigate()
    const [form, setForm] = React.useState({ name: "", mobile: "", email: "", password: "", confirm_password: "" })

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        http.post('/api/signup', form).then((res) => {
            if (res.data) {
                navigate('/')
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    return (
        <>
            <div className="main">
                <div className="create-join my-3">
                    <div className="text-center my-5">
                        <span className="heading text-center">Sign Up</span>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-6">
                            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 ">Your name</label>
                            <input type="name" id="name" name="name" onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" required />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="mobile" className="block mb-2 text-sm font-medium text-gray-900 ">Your mobile</label>
                            <input type="mobile" id="mobile" name="mobile" onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" required />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Your email</label>
                            <input type="email" id="email" name="email" onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" required />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">Your password</label>
                            <input type="password" id="password" name="password" onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" required />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="cpassword" className="block mb-2 text-sm font-medium text-gray-900 ">Confirm password</label>
                            <input type="password" id="cpassword" name="confirm_password" onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" required />
                        </div>
                        <button type="submit" id="loginBtn" className="createroom-butt unselectable">Sign Up</button>
                        <br />
                        <OAuth />
                    </form>
                </div>
            </div>
        </>
    )
}

export default Signup;