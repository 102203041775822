import CryptoJS from "crypto-js";
import sha1 from 'sha1'

export const encryptData = (text) => {
    let Secret;
    if (process.env.REACT_APP_SECRET) {
        Secret = process.env.REACT_APP_SECRET
    } else {
        Secret = "asdjklhfqwepusdjkabnfjkasdhfuiqwhruifhifb"
    }

    let sha1Hash = CryptoJS.SHA1(JSON.stringify(text) + Secret).toString();
    return encodeURIComponent(sha1Hash);
};

export const encryptSha1 = (text) => {
    return sha1(text);
}

export const decryptData = (passHash) => {
    let Secret;
    if (process.env.REACT_APP_SECRET) {
        Secret = process.env.REACT_APP_SECRET
    } else {
        Secret = "asdjklhfqwepusdjkabnfjkasdhfuiqwhruifhifb"
    }
    try {
        var bytes = CryptoJS.AES.decrypt(passHash, Secret);
        if (bytes) {
            var decryptedString = bytes.toString(CryptoJS.enc.Utf8);
            if (decryptedString) {
                return { pass: decryptedString.replace(/^"(.*)"$/, '$1'), message: 'verified' }
            } else {
                return "not verified";
            }
        } else {
            return "not verified";
        }
    } catch (error) {
        return "not verified";
    }
}