import React from 'react';
import './Tray.css';
import {
  CameraOn,
  Leave,
  CameraOff,
  MicrophoneOff,
  MicrophoneOn,
  Screenshare,
  ChatIcon,
  ChatHighlighted,
} from './Icons';
import Chat from '../chat/Chat'
import ScreenRecorder from '../../components/ScreenRecording'
import ScreenShareIcon from '@mui/icons-material/ScreenShare';

export default function Tray({
  leaveCall, micToggle, mutedAudio, videoToggle,
  mutedVideo, startScreenShare, stopScreenShare,
  isSharingScreen, handleSendMessage, messages,
  setShowChat, showChat, recvMsg, setRecvMsg,
  userDetails, roomTotalUser, unseenMessageCount, setUnseenMessageCount,
  usertype, roomId
}) {

  const { handleStartRecording, stopRecording, recorder, showRecordingDot } = ScreenRecorder(roomId)

  const toggleChat = () => {
    setShowChat(!showChat);
    if (recvMsg) {
      setRecvMsg(!recvMsg);
      setUnseenMessageCount(0)
    }
  };

  return (
    <>
      <div className="tray">
        <Chat showChat={showChat} messages={messages} handleSendMessage={handleSendMessage} roomTotalUser={roomTotalUser} userDetails={userDetails} />
        <div className="tray-buttons-container">
          <div className="controls">
            <button type="button" onClick={videoToggle}>
              {mutedVideo ? <CameraOff /> : <CameraOn />}
              <span className='icon-span'>
                {mutedVideo ? 'Turn camera on' : 'Turn camera off'}
              </span>
            </button>
            <button type="button" onClick={micToggle}>
              {mutedAudio ? <MicrophoneOff /> : <MicrophoneOn />}
              <span className='icon-span'>
                {mutedAudio ? 'Unmute mic' : 'Mute mic'}
              </span>
            </button>
          </div>
          <div className="actions">
            {isSharingScreen ?
              <button type="button" onClick={stopScreenShare}>
                <ScreenShareIcon />
                <span className='icon-span'>
                  Stop sharing screen
                </span>
              </button>
              :
              <button type="button" onClick={startScreenShare}>
                <ScreenShareIcon />
                <span className='icon-span'>
                  Share screen
                </span>
              </button>
            }

            {(usertype == 'interviewer' || usertype == 'counsellor') &&
              (showRecordingDot ?
                <button type="button" onClick={stopRecording}>
                  <Screenshare />
                  <span className='icon-span'>
                    Stop recording
                  </span>
                </button>
                :
                <button type="button" onClick={handleStartRecording}>
                  <Screenshare />
                  <span className='icon-span'>
                    Start Record
                  </span>
                </button>
              )
            }
            <button type="button" onClick={toggleChat}>
              {recvMsg ? <div className="relative inline-flex items-center text-sm font-medium text-center text-white ">
                <ChatHighlighted />
                <div className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2">{unseenMessageCount}</div>
              </div>
                : <ChatIcon />}
              <span className='icon-span'>
                {showChat ? 'Hide chat' : 'Show chat'}
              </span>
            </button>
          </div>
          <div className="leave">
            <button onClick={() => leaveCall()} type="button">
              <Leave />
              <span className='icon-span'>
                Leave call
              </span>
            </button>
          </div>
        </div>
      </div >
    </>
  );
}
