import * as React from "react";
import { CircularProgress, Box, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from "react-redux";
import http from "./utils/http";
import { CLOSE_SNACKBAR } from "./store/common";
import { SET_USER, SET_TOKEN } from "./store/auth";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import "./App.css";

import Room from "./screens/Webrtc/Room";
import Home from "./screens/Home";
import Login from "./screens/Login";
import Signup from "./screens/Signup";
import Navbar from "./components/Navbar";
import CreateMeeting from "./screens/CreateMeeting";
import JoinMeeting from "./screens/JoinMeeting";
import MeetingDetails from "./screens/MeetingDetails";

const token = localStorage.getItem("token");

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const snackbar = useSelector(state => state.common.snackbar);
  const user = useSelector(state => state.auth.user);

  React.useEffect(() => {
    if (token) {
      http.get('/api/auth').then((res) => {
        dispatch(SET_USER(res.data));
      }).catch((error) => {
        dispatch(SET_USER({}));
        dispatch(SET_TOKEN(''));
      })
    } else {
      dispatch(SET_USER({}));
      dispatch(SET_TOKEN(''));
    }
    return () => true
  }, [dispatch]);

  if (user == null) {
    return <Box className="flex h-screen w-screen items-center justify-center">
      <CircularProgress />
    </Box>
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(CLOSE_SNACKBAR({}));
  };

  return (
    <div className="App">
      <Routes>
        {Object.keys(user).length > 0 ?
          <>
            <Route path="/" element={<Navbar />} >
              <Route index element={<Home />} />
              <Route path="/createMeeting" element={<CreateMeeting />} />
              <Route path="/joinMeeting" element={<JoinMeeting />} />
              <Route path="/meetingDetails" element={<MeetingDetails />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          </>
          :
          <>
            <Route path="/" element={<Navbar />} >
              <Route index element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="*" element={<Navigate to="/" state={{ prevUrl: location.pathname }} replace />} />
            </Route>
          </>
        }
        <Route path="/room/:roomId/:roomPass?/:username?/:usertype?" element={<Room />} />
      </Routes>
      <Snackbar open={snackbar.status} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={snackbar.duration || null} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={snackbar.type} sx={{ width: '100%' }}>{snackbar.message}</Alert>
      </Snackbar>
    </div>
  );
}

export default App;
