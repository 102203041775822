import { createSlice } from '@reduxjs/toolkit'

export const commonSlice = createSlice({
    name: 'common',
    initialState: {
        snackbar: { status: false, message: '', type: 'success', duration: null }
    },
    reducers: {
        OPEN_SNACKBAR: (state, action) => {
            state.snackbar = { duration: 6000, ...action.payload, status: true }
        },
        CLOSE_SNACKBAR: (state, action) => {
            state.snackbar = { ...action.payload, status: false, message: '', type: 'success', duration: null }
        },
        OPEN_VALIDATION: (state, action) => {
            state.snackbar = { duration: 5000, ...action.payload, type: 'error', status: true }
        }
    }
})

// Action creators are generated for each case reducer function
export const { OPEN_SNACKBAR, CLOSE_SNACKBAR, OPEN_VALIDATION } = commonSlice.actions

export default commonSlice.reducer