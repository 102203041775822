import { useState, useEffect, useCallback, useRef } from "react";
import http from "../utils/http";

let mediaStream = null;
let recorder = null;

function ScreenRecorder(id) {
    const [chunks, setChunks] = useState([]);
    const submitFinalRef = useRef(false);
    const [showRecordingDot, setShowRecordingDot] = useState(false);
    const [timer, setTimer] = useState(0);

    const handleStartRecording = () => {
        const options = { mimeType: "video/webm; codecs=vp9" };

        navigator.mediaDevices.getDisplayMedia({
            audio: true,
            video: {
                mediaSource: 'screen',
                cursor: 'always',
                displaySurface: 'monitor',
                frameRate: { ideal: 60, max: 120 }
            }
        }).then((stream) => {
            mediaStream = stream;
            recorder = new MediaRecorder(stream, options);

            let interval;
            let timer;
            recorder.onstart = () => {
                setTimer(0);
                setShowRecordingDot(true);
                interval = setInterval(() => {
                    recorder.requestData();
                }, 5000);
                timer = setInterval(() => {
                    setTimer((prevTimer) => prevTimer + 1000); // Increment by 1 second (1000 milliseconds)
                }, 1000); // Execute every 1 second
            };

            recorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    setChunks((prevChunks) => [...prevChunks, event.data]);
                }
            };

            recorder.onstop = () => {
                stopRecording();
                setShowRecordingDot(false);
                clearInterval(interval);
                clearInterval(timer);
                setChunks([]);
            };

            // Handle stop sharing action triggered by the user
            const tracks = stream.getVideoTracks();
            if (tracks.length > 0) {
                const videoTrack = tracks[0];
                videoTrack.onended = () => {
                    stopRecording();
                };
            }

            recorder.start();
        });
    };

    const saveVideo = useCallback((blob, final = false) => {
        const formData = new FormData();
        formData.append('data', blob, 'video.webm');

        if (submitFinalRef.current || final) {
            formData.append('final', 'final');
        }
        http.post(`/api/saverRecordingVideo/${id}`, formData)
            .then((response) => {
                console.log(response.data);
                setChunks([]);
            })
            .catch((error) => console.log(error));
    }, [id, submitFinalRef]);

    useEffect(() => {
        if (chunks.length > 0) {
            const blob = new Blob(chunks, { type: "video/webm" });
            saveVideo(blob);
            setChunks([]);
        }
    }, [chunks, saveVideo]);

    const stopRecording = () => {
        submitFinalRef.current = true;
        setTimer(0);

        if (recorder && recorder.state === "recording") {
            recorder.stop();
        }
        if (mediaStream) {
            mediaStream.getTracks().forEach(track => {
                track.stop()
            });
        }

        const blob = new Blob(chunks, { type: "video/webm" });
        saveVideo(blob, true);

        setShowRecordingDot(false)
        setChunks([]);
    };

    return { handleStartRecording, stopRecording, recorder, showRecordingDot, timer };
}

export default ScreenRecorder;
