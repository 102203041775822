import * as React from "react";
import http from '../utils/http'
import { useNavigate } from "react-router-dom";
import { encryptSha1 } from '../utils/helper'
import { useSelector } from "react-redux";

const JoinMeeting = () => {
    const navigate = useNavigate()
    const user = useSelector(state => state.auth.user);
    const [form, setForm] = React.useState({ meetingID: "", password: "", username: user.name, usertype: 'log' })

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        http.post('/api/joinMeeting', form).then((res) => {
            if (res.data) {
                let roomPass = encryptSha1(res.data.meeting.MeetingPassword)
                navigate(`/room/${res.data.meeting.MeetingID}/${roomPass}/${user.name}/log`)
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    return (
        <>
            <div className="main">
                <div className="create-join my-3">
                    <div className="text-center my-5">
                        <span className="heading text-center">Join Meeting</span>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-6">
                            <label htmlFor="meetingID" className="block mb-2 text-sm font-medium text-gray-900 ">Meeting ID</label>
                            <input type="text" id="meetingID" name="meetingID" onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5" required />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">Meeting Password</label>
                            <input type="password" id="password" name="password" onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5" required />
                        </div>
                        <button type="submit" className="createroom-butt unselectable">Join</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default JoinMeeting;