// Import the functions you need from the SDKs you need
import { initializeApp } from '@firebase/app';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCb8aredoucY30BCO2XQtiuYm9zdx1Zkfk",
  authDomain: "cleveratti-meet.firebaseapp.com",
  projectId: "cleveratti-meet",
  storageBucket: "cleveratti-meet.appspot.com",
  messagingSenderId: "566968301185",
  appId: "1:566968301185:web:8a66be8a136638010bbf80",
  measurementId: "G-2PSS14FZDN"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
