import React from 'react';
import { Link } from 'react-router-dom'

const Home = () => {
    return (
        <>
            <div className="main">
                <div className="create-join">
                    <Link to="/createMeeting">
                        <button className="createroom-butt unselectable">Create Meeting</button>
                    </Link>
                    <Link to="/joinMeeting">
                        <button className="createroom-butt unselectable">Join Meeting</button>
                    </Link>
                    <Link to="/meetingDetails">
                        <button className="createroom-butt unselectable">Your All Meeting</button>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default Home;