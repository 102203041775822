import { GoogleAuthProvider, getAuth, signInWithPopup } from '@firebase/auth';
import { app } from '../firebase';
import { useDispatch } from 'react-redux';
import { SET_USER, SET_TOKEN } from "../store/auth";
import { useNavigate, useLocation } from 'react-router-dom';
import http from "../utils/http";

export default function OAuth() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const handleGoogleClick = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const auth = getAuth(app);
      const result = await signInWithPopup(auth, provider);

      http.post('/api/google', {
        name: result.user.displayName,
        email: result.user.email,
      }).then((res) => {
        if (res.data) {
          dispatch(SET_USER(res.data.data));
          dispatch(SET_TOKEN(res.data.token));
          navigate(location?.state?.prevUrl ? location?.state?.prevUrl : '/');
        }
      }).catch((error) => {
        dispatch(SET_USER({}));
        dispatch(SET_TOKEN(''));
      })
    } catch (error) {
      console.log('could not sign in with google', error);
    }
  };
  return (
    <button
      onClick={handleGoogleClick}
      type='button'
      id="googleButton"
      className="createroom-butt unselectable"
    >
      Continue with google
    </button>
  );
}
