import * as React from "react";
import http from '../utils/http'
import { useNavigate } from "react-router-dom";
import { encryptSha1 } from '../utils/helper'
import { useSelector } from "react-redux";

const MeetingDetails = () => {
    const navigate = useNavigate()
    const [data, setData] = React.useState([])
    const user = useSelector(state => state.auth.user);

    React.useEffect(() => {
        http.get('/api/getUserMeeting').then((res) => {
            if (res.data) {
                setData(res.data)
            }
        }).catch((e) => {
            console.log(e)
        })
    }, [])

    function formatMeetingDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleString('en-US', options);
        return formattedDate;
    }

    const handleJoinMeeting = (m) => {
        let data = {
            meetingID: m.MeetingID,
            username: user.name,
            usertype: 'log'
        }
        http.post('/api/joinMeeting', data).then((res) => {
            if (res.data.message === "Meeting joined successfully.") {
                let roomPass = encryptSha1(res.data.meeting.MeetingPassword)
                localStorage.setItem('meetingPassword', roomPass)
                navigate(`/room/${m.MeetingID}/${roomPass}/${user.name}/log`)
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    return (
        <>
            <div className="main">
                <div className="create-join my-3">
                    <div className="text-center my-5">
                        <span className="heading text-center">Your All Meetings Details</span>
                    </div>
                </div>
            </div>
            <div className="mx-5 text-center">
                {data.length > 0 ? data.map((m, i) => {
                    return <div key={i} className="max-w-sm p-6 inline-block text-left sm:m-1 my-2 w-full sm:w-1/2 lg:w-1/2 bg-white border border-gray-200 rounded-lg shadow ">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">{m.MeetingName}</h5>
                        <p className="mb-3 font-normal text-gray-700 ">Meeting ID: {m.MeetingID}</p>
                        <p className="mb-3 font-normal text-gray-700 ">Meeting Password: {m.MeetingPassword}</p>
                        <p className="mb-3 font-normal text-gray-700 ">Start Time: {formatMeetingDate(m.MeetingStartTime)}</p>
                        <p className="mb-3 font-normal text-gray-700 ">End Time: {formatMeetingDate(m.MeetingEndTime)}</p>
                        <button onClick={() => handleJoinMeeting(m)} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 ">
                            Join Meeting
                            <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                            </svg>
                        </button>
                    </div>
                }) : "No Meeting Available"}
            </div>
        </>
    )
}

export default MeetingDetails;