import * as React from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { SET_USER, SET_TOKEN } from "../store/auth";
import { useDispatch, useSelector } from "react-redux";

const Navbar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = React.useState(false)
    const user = useSelector(state => state.auth.user);
    const token = useSelector(state => state.auth.token);

    React.useEffect(() => {
        if (token && user) {
            setIsLogin(true)
        } else {
            setIsLogin(false)
        }
        return () => true
    }, [dispatch, token, user]);

    const handleLogout = () => {
        localStorage.removeItem('token')
        dispatch(SET_USER({}));
        dispatch(SET_TOKEN(''));
        navigate('/')
    }

    return (
        <>
            <div className='relative' >
                <nav className="bg-white w-full border-b border-gray-200 ">
                    <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                        <Link to="/" className="flex items-center">
                            <span className="self-center text-2xl font-semibold whitespace-nowrap">Cleveratti</span>
                        </Link>
                        <div className="flex md:order-2">
                            {isLogin ?
                                <button onClick={handleLogout} className="text-white bg-[#f56565] hover:bg-[#fc8181] font-medium rounded-lg text-sm px-4 py-2 text-center mr-1 md:mr-[10px] ">
                                    Logout
                                </button>
                                :
                                <>
                                    <Link to="/" className="text-white bg-[#6366f1] font-medium rounded-lg text-sm px-4 py-2 text-center mr-1 md:mr-[10px] ">
                                        SignIn
                                    </Link>
                                    <Link to="/signup" className="text-white bg-[#ed64a6] hover:bg-[#f687b3] font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 ">
                                        SignUp
                                    </Link>
                                </>}
                        </div>
                    </div>
                </nav>

                <div>
                    <Outlet />
                </div>
            </div>
        </>
    )
}

export default Navbar;