import React, { useEffect, useCallback, useState, useRef, useMemo } from "react";
import { io } from "socket.io-client";
import { useParams } from 'react-router-dom';
import Tray from '../Tray/Tray'
import MeetingContext from "../../context/MeetingContext";

const Room = () => {
  const socket = useMemo(() => io(process.env.REACT_APP_API_URL), []);

  const { roomId, username, usertype } = useParams();
  const userId = username;

  const context = React.useContext(MeetingContext)
  const { constraints, myPeerConnection, createVideo } = context;

  const [socketConnect, setSocketConnect] = useState(false);
  const [screenSharing, setScreenSharing] = useState(false);
  const [selfScreenSharing, setSelfScreenSharing] = useState(false);
  const [userScreenSharing, setUserScreenSharing] = useState(false);
  const [roomFull, setRoomFull] = useState(false);
  const [videoStatus, setVideoStatus] = useState(false);
  const [roomJoined, setRoomJoined] = useState(false);
  const [mutedAudio, setMutedAudio] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [localSocketId, setLocalSocketId] = useState();
  const [recvMsg, setRecvMsg] = useState(false);
  const [videoAllowed, setVideoAllowed] = useState(true);
  const [audioAllowed, setAudioAllowed] = useState(true);
  const [roomTotalUser, setRoomTotalUser] = useState(0);
  const [unseenMessageCount, setUnseenMessageCount] = useState(0);
  const [userDetails, setUserDetails] = useState({});
  const [messages, setMessages] = useState([]);

  const connections = useRef({});
  const cName = useRef({});
  const micInfo = useRef({});
  const videoInfo = useRef({});
  const audioTrackSent = useRef({});
  const videoTrackSent = useRef({});
  const localVideoRef = useRef(null);
  const videoContainer = useRef(null);

  // When Socket Connect
  const handleConnect = useCallback(() => {
    setSocketConnect(true);
    socket.emit("join:room", { userId, roomId });
  }, [socket, roomId, userId]);

  const getLocalVideo = useCallback(async () => {
    try {
      const localStream = await navigator.mediaDevices.getUserMedia(constraints);
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = localStream;
        localVideoRef.current.muted = true;
      }
      return localStream;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }, [constraints]);

  // Send Local Tracks to connection
  const handleSendTracks = useCallback(async () => {
    try {
      const localStream = await getLocalVideo();
      localStream.getTracks().forEach(track => {
        for (let key in connections.current) {
          connections.current[key].addTrack(track, localStream);
          if (track.kind === 'audio') {
            audioTrackSent[key] = track;
          } else {
            videoTrackSent[key] = track;
          }
        }
      });
    } catch (err) {
      console.error(err);
    }
  }, []);

  // When Room Connect
  const handleJoinRoom = useCallback(async (connection, connectionNames, micSocket, videoSocket, socketId) => {
    try {
      setRoomJoined(true);
      setLocalSocketId(socketId)
      if (connectionNames) {
        cName.current = connectionNames;
      }
      if (micSocket) {
        micInfo.current = micSocket;
      }
      if (videoSocket) {
        videoInfo.current = videoSocket;
      }

      if (connection) {
        connection.forEach((sid) => {
          if (!connections.current[sid] && myPeerConnection) {
            connections.current[sid] = new RTCPeerConnection(myPeerConnection);
            connections.current[sid].onicecandidate = function (event) {
              if (event.candidate) {
                socket.emit("new:icecandidate", event.candidate, sid);
              }
            };

            connections.current[sid].ontrack = (event) => {
              if (!document.getElementById(sid)) {
                createVideo(cName.current[sid], sid, event.streams[0], micInfo, videoInfo, videoContainer)
              }
            };

            connections.current[sid].onremovetrack = () => {
              if (document.getElementById(sid)) {
                document.getElementById(sid).remove();
              }
            };

            connections.current[sid].onnegotiationneeded = async () => {
              try {
                const offer = await connections.current[sid].createOffer();
                await connections.current[sid].setLocalDescription(offer);
                socket.emit("video:offer", connections.current[sid].localDescription, sid);
              } catch (error) {
                console.log(error);
              }
            };
          }
        });

        await handleSendTracks();
        console.log("Added all sockets to connection ");
      } else {
        console.log("Waiting for someone to join");
        await getLocalVideo()
      }
    } catch (error) {
      console.log(error);
    }
  }, [socket, handleSendTracks, getLocalVideo, myPeerConnection]);

  // Handle Video Offer
  const handleVideoOffer = useCallback(async (offer, sid, cname, micInformation, vidInformation) => {
    try {
      if (cname) {
        cName.current[sid] = cname;
      }
      if (micInformation) {
        micInfo.current[sid] = micInformation;
      }
      if (vidInformation) {
        videoInfo.current[sid] = vidInformation;
      }

      if (!connections.current[sid] && myPeerConnection) {
        connections.current[sid] = new RTCPeerConnection(myPeerConnection);
        connections.current[sid].onicecandidate = function (event) {
          if (event.candidate) {
            socket.emit("new:icecandidate", event.candidate, sid);
          }
        };

        connections.current[sid].ontrack = (event) => {
          if (!document.getElementById(sid)) {
            createVideo(cName.current[sid], sid, event.streams[0], micInfo, videoInfo, videoContainer)
          }
        };

        connections.current[sid].onremovetrack = () => {
          if (document.getElementById(sid)) {
            document.getElementById(sid).remove();
          }
        };

        connections.current[sid].onnegotiationneeded = async () => {
          try {
            const offer = await connections.current[sid].createOffer();
            await connections.current[sid].setLocalDescription(offer);
            socket.emit("video:offer", connections.current[sid].localDescription, sid);
          } catch (error) {
            console.log(error);
          }
        };

        const desc = new RTCSessionDescription(offer);

        connections.current[sid].setRemoteDescription(desc)
          .then(() => {
            localVideoRef.current.srcObject.getTracks().forEach(track => {
              connections.current[sid].addTrack(track, localVideoRef.current.srcObject);
              if (track.kind === 'audio') {
                audioTrackSent[sid] = track;
                if (!audioAllowed) {
                  audioTrackSent[sid].enabled = false;
                }
              }
              else {
                videoTrackSent[sid] = track;
                if (!videoAllowed) {
                  videoTrackSent[sid].enabled = false
                }
              }
            })
          })
          .then(() => {
            return connections.current[sid].createAnswer();
          })
          .then(answer => {
            return connections.current[sid].setLocalDescription(answer);
          })
          .then(() => {
            socket.emit('video:answer', connections.current[sid].localDescription, sid);
          })
          .catch((err) => console.log(err));
      }
    } catch (error) {
      console.log(error);
    }
  }, [socket, audioAllowed, videoAllowed, myPeerConnection]);

  // Handle New Ice Candidate
  const handleNewIceCandidate = useCallback(async (candidate, sid) => {
    try {
      const newCandidate = new RTCIceCandidate(candidate);
      await connections.current[sid].addIceCandidate(newCandidate).catch(console.log);
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Handle Video Answer
  const handleVideoAnswer = useCallback(async (answer, sid) => {
    try {
      const ans = new RTCSessionDescription(answer);
      await connections.current[sid].setRemoteDescription(ans);
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Handle Total User Count In Your Room
  const handleUserCount = useCallback((usersCount, users) => {
    setUserDetails(users)
    setRoomTotalUser(usersCount);
  }, []);

  //Remove User Stream When User Disconnect
  const handleDisconnect = useCallback((sid) => {
    if (document.getElementById(sid)) {
      document.getElementById(sid).remove();
    }
    delete connections.current[sid];
  }, [])

  // Handle Start Share Screen
  const startScreenShare = () => {
    let screenMediaPromise;

    if (!screenSharing) {
      screenMediaPromise = navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });
    }

    screenMediaPromise.then((stream) => {
      setScreenSharing(true);
      // Replace the camera track with the screen track
      for (let key in connections.current) {
        const sender = connections.current[key].getSenders().find((s) => (s.track ? s.track.kind === "video" : false));
        sender.replaceTrack(stream.getVideoTracks()[0]);
      }

      localVideoRef.current.srcObject = stream;
      setSelfScreenSharing(true);
      socket.emit("screen:share", true)

      localVideoRef.current.ondblclick = () => {
        if (selfScreenSharing) {
          if (localVideoRef.current.requestFullscreen) {
            localVideoRef.current.requestFullscreen();
          } else if (localVideoRef.current.mozRequestFullScreen) {
            /* Firefox */
            localVideoRef.current.mozRequestFullScreen();
          } else if (localVideoRef.current.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            localVideoRef.current.webkitRequestFullscreen();
          } else if (localVideoRef.current.msRequestFullscreen) {
            /* IE/Edge */
            localVideoRef.current.msRequestFullscreen();
          }
        }
      };

      localVideoRef.current.srcObject.getVideoTracks()[0].onended = function () {
        console.log("Stop Sharing")
        setScreenSharing(false);
        setSelfScreenSharing(false);
        stopScreenShare();
      };
    })
      .catch((e) => {
        alert("Unable to share screen:" + e.message);
        console.error(e);
      });
  };

  //Handle Stop Screen Sharing
  const stopScreenShare = () => {
    if (screenSharing) {
      const screenTrack = localVideoRef.current.srcObject.getVideoTracks()[0];
      screenTrack.stop();
    }

    getLocalVideo().then((stream) => {
      for (let key in connections.current) {
        const sender = connections.current[key].getSenders().find((s) => (s.track ? s.track.kind === "video" : false));
        sender.replaceTrack(stream.getVideoTracks()[0]);
      }
      setScreenSharing(false);
      setSelfScreenSharing(false);
      socket.emit("screen:share", false)
    })
      .catch((e) => {
        alert("Unable to stop share screen:" + e.message);
        console.error(e);
      });
  };

  const handleScreenShare = useCallback((screenShareStatus, sid) => {
    const screenSharingElement = document.getElementById(sid);

    if (screenShareStatus) {
      if (screenSharingElement) {
        screenSharingElement.classList.add("tile-screenshare")
        const videoElement = screenSharingElement.querySelector('video');
        setUserScreenSharing(true)
        if (videoElement) {
          videoElement.style.transform = "";
          videoElement.setAttribute('data-mirrored', 'false');

          videoElement.ondblclick = () => {
            if (userScreenSharing) {
              if (videoElement.requestFullscreen) {
                videoElement.requestFullscreen();
              } else if (videoElement.mozRequestFullScreen) {
                /* Firefox */
                videoElement.mozRequestFullScreen();
              } else if (videoElement.webkitRequestFullscreen) {
                /* Chrome, Safari and Opera */
                videoElement.webkitRequestFullscreen();
              } else if (videoElement.msRequestFullscreen) {
                /* IE/Edge */
                videoElement.msRequestFullscreen();
              }
            }
          };
        }
      }
    } else {
      if (screenSharingElement) {
        screenSharingElement.classList.remove("tile-screenshare")
        const videoElement = screenSharingElement.querySelector('video');
        if (videoElement) {
          videoElement.style.transform = "scale(-1, 1)";
          videoElement.setAttribute('data-mirrored', 'true');
        }
        setUserScreenSharing(false)
      }
    }
  }, [])

  const handleReceiveMessages = useCallback((msg, senderName, time) => {
    if (senderName != userId && !showChat) {
      setRecvMsg(true)
      setUnseenMessageCount(prevCount => prevCount + 1)
    }
    setMessages((prevMessages) => [
      ...prevMessages,
      { msg, senderName, time }
    ]);
  }, [showChat, userId]);

  const handleActions = useCallback((msg, sid) => {
    if (msg === 'mute') {
      document.querySelector(`#mute${sid}`).style.visibility = 'visible';
      micInfo.current[sid] = 'off';
    }
    else if (msg === 'unmute') {
      document.querySelector(`#mute${sid}`).style.visibility = 'hidden';
      micInfo.current[sid] = 'on';
    }
    else if (msg === 'videoOff') {
      document.querySelector(`#vidOff${sid}`).style.visibility = 'visible';
      document.querySelector(`#videoDiv${sid}`).style.background = 'black';
      document.querySelector(`#video${sid}`).style.visibility = 'hidden';
      videoInfo.current[sid] = 'off';
    }
    else if (msg === 'videoOn') {
      document.querySelector(`#vidOff${sid}`).style.visibility = 'hidden';
      document.querySelector(`#videoDiv${sid}`).style.background = 'transparent';
      document.querySelector(`#video${sid}`).style.visibility = 'visible';
      videoInfo.current[sid] = 'on';
    }
  }, [])

  const handleRoomFull = useCallback(() => {
    setRoomFull(true);
  }, [])

  useEffect(() => {
    socket.on("connect", handleConnect);
    socket.on("join:room", handleJoinRoom);
    socket.on("user:count", handleUserCount);
    socket.on("video:offer", handleVideoOffer);
    socket.on("new:icecandidate", handleNewIceCandidate);
    socket.on("video:answer", handleVideoAnswer);
    socket.on("remove:peer", handleDisconnect);
    socket.on("screen:share", handleScreenShare);
    socket.on("message", handleReceiveMessages);
    socket.on("action", handleActions);
    socket.on("room:full", handleRoomFull);
    return () => {
      socket.off("connect", handleConnect);
      socket.off("join:room", handleJoinRoom);
      socket.off("user:count", handleUserCount);
      socket.off("video:offer", handleVideoOffer);
      socket.off("new:icecandidate", handleNewIceCandidate);
      socket.off("video:answer", handleVideoAnswer);
      socket.off("remove:peer", handleDisconnect);
      socket.off("screen:share", handleScreenShare);
      socket.off("message", handleReceiveMessages);
      socket.off("action", handleActions);
      socket.off("room:full", handleRoomFull);
    };
  }, [
    socket,
    handleConnect,
    handleJoinRoom,
    handleUserCount,
    handleVideoOffer,
    handleNewIceCandidate,
    handleVideoAnswer,
    handleDisconnect,
    handleScreenShare,
    handleReceiveMessages,
    handleActions,
    handleRoomFull
  ]);

  //Stop Camera Function
  const stopCamera = () => {
    if (localVideoRef.current?.srcObject) {
      const localStream = localVideoRef.current.srcObject;

      if (localStream) {
        localStream.getTracks().forEach((track) => {
          track.stop();
        });
      }

    }
  }

  //Send Message To ALL
  const handleSendMessage = (message) => {
    socket.emit('message', message, userId, roomId);
  }

  //Handle Mic
  const handleMic = () => {
    if (audioAllowed) {
      setMutedAudio(true)
      for (let key in audioTrackSent) {
        audioTrackSent[key].enabled = false;
      }
      setAudioAllowed(false);
      if (localVideoRef.current.srcObject) {
        localVideoRef.current.srcObject.getTracks().forEach(track => {
          if (track.kind === 'audio')
            track.enabled = false;
        })
      }
      socket.emit('action', 'mute');
    } else {
      setMutedAudio(false)
      for (let key in audioTrackSent) {
        audioTrackSent[key].enabled = true;
      }
      setAudioAllowed(true);
      if (localVideoRef.current.srcObject) {
        localVideoRef.current.srcObject.getTracks().forEach(track => {
          if (track.kind === 'audio')
            track.enabled = true;
        })
      }

      socket.emit('action', 'unmute');
    }
  }

  //Handle Video
  const handleVideo = () => {
    if (videoAllowed) {
      setVideoStatus(true)
      for (let key in videoTrackSent) {
        videoTrackSent[key].enabled = false;
      }
      setVideoAllowed(false)
      localVideoRef.current.style.visibility = "hidden";
      // document.querySelector(`#localVideo`).style.background = 'black';
      if (localVideoRef.current.srcObject) {
        localVideoRef.current.srcObject.getTracks().forEach(track => {
          if (track.kind === 'video') {
            track.enabled = false;
          }
        })
      }
      socket.emit('action', 'videoOff');
      stopCamera()
    } else {
      setVideoStatus(false)
      for (let key in videoTrackSent) {
        videoTrackSent[key].enabled = true;
      }
      setVideoAllowed(true)
      localVideoRef.current.style.visibility = "visible";
      // document.querySelector(`#localVideo`).style.background = 'transparent';
      if (localVideoRef.current.srcObject) {
        localVideoRef.current.srcObject.getTracks().forEach(track => {
          if (track.kind === 'video')
            track.enabled = true;
        })
      }

      socket.emit('action', 'videoOn');
      getLocalVideo().then((stream) => {
        // Replace the camera track with the screen track
        for (let key in connections.current) {
          const sender = connections.current[key].getSenders().find((s) => (s.track ? s.track.kind === "video" : false));
          sender.replaceTrack(stream.getVideoTracks()[0]);
        }
      })
        .catch((e) => {
          alert("Unable to start camera :" + e.message);
          console.error(e);
        });
    }
  }

  //Handle When User Cut Call
  const handleEndCall = useCallback(() => {
    if (roomJoined) {
      console.log('Handle End Call');
      for (let sid in connections.current) {
        connections.current[sid].close();
      }
      stopCamera();
      socket.disconnect()
      if (videoContainer.current) {
        videoContainer.current.remove();
      }

      window.location.href = '/';
    }
  }, [roomJoined, socket])

  useEffect(() => {
    console.info('started');
    return () => {
      handleEndCall();
    }
  }, [handleEndCall]);

  return (
    roomFull ? <div className="flex justify-center items-center min-h-screen">Room Full</div> :
      socketConnect && roomJoined && <div className="room">
        <div className={(screenSharing || userScreenSharing) ? "screenshare" : "call"} ref={videoContainer}>
          <div id="remoteStream" className={selfScreenSharing ? "tile-screenshare" : "tile-video self-view alone"}>
            <video ref={localVideoRef} data-mirrored={selfScreenSharing ? "false" : "true"} data-playable="true" data-local="true" data-session-id={localSocketId} data-video-type="video" style={{ transform: `${selfScreenSharing ? "" : "scale(-1, 1)"}` }} autoPlay muted playsInline></video>
            <div className="nameTag">{userId} (you)</div>
          </div>
        </div>
        <Tray
          leaveCall={handleEndCall}
          micToggle={handleMic}
          mutedAudio={mutedAudio}
          videoToggle={handleVideo}
          mutedVideo={videoStatus}
          isSharingScreen={screenSharing}
          startScreenShare={startScreenShare}
          stopScreenShare={stopScreenShare}
          handleSendMessage={handleSendMessage}
          messages={messages}
          userDetails={userDetails}
          setShowChat={setShowChat}
          showChat={showChat}
          recvMsg={recvMsg}
          setRecvMsg={setRecvMsg}
          roomTotalUser={roomTotalUser}
          unseenMessageCount={unseenMessageCount}
          setUnseenMessageCount={setUnseenMessageCount}
          usertype={usertype}
          roomId={roomId}
        />
      </div>
  );
};

export default Room;
