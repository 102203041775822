import React, { useMemo } from "react";
import MeetingContext from "./MeetingContext";

const MeetingState = (props) => {

    const createVideo = (userName, sid, streams, micInfo, videoInfo, videoContainer) => {
        let vidCont = document.createElement("div");
        let newVideo = document.createElement('video');
        let name = document.createElement("div");
        let muteIcon = document.createElement('div');
        let videoOff = document.createElement('div');
        let videoDiv = document.createElement('div');
        let micToggle = document.createElement('button');
        let vidToggle = document.createElement('button');

        name.classList.add("nameTag");
        videoOff.classList.add('video-off');
        muteIcon.classList.add('mute-icon');
        micToggle.classList.add('mr-2');
        vidToggle.classList.add('mr-2');
        videoDiv.classList.add('videoParent');
        newVideo.classList.add('w-full', 'h-full');

        name.innerHTML = `${userName}`;
        muteIcon.innerHTML = "Mic Off"
        videoOff.innerHTML = 'Video Off'

        vidCont.id = sid;
        muteIcon.id = `mute${sid}`;
        videoOff.id = `vidOff${sid}`;
        videoDiv.id = `videoDiv${sid}`
        newVideo.id = `video${sid}`;

        newVideo.autoplay = true;
        newVideo.playsinline = true;
        newVideo.srcObject = streams;
        newVideo.style.transform = "scale(-1, 1)";
        newVideo.setAttribute('data-mirrored', 'true');
        newVideo.setAttribute('data-playable', 'true');
        newVideo.setAttribute('data-local', 'false');
        newVideo.setAttribute('data-session-id', sid);
        newVideo.setAttribute('data-video-type', 'video');

        if (micInfo.current[sid] === 'on') {
            muteIcon.style.visibility = 'hidden';
            micToggle.innerHTML = "Mic On"
        } else {
            muteIcon.style.visibility = 'visible';
            micToggle.innerHTML = "Mic Off"
        }

        if (videoInfo.current[sid] === 'on') {
            videoOff.style.visibility = 'hidden';
            newVideo.style.visibility = 'visible'
            videoDiv.style.background = 'transparent'
            vidToggle.innerHTML = "Video On"
        } else {
            videoOff.style.visibility = 'visible';
            newVideo.style.visibility = 'hidden'
            videoDiv.style.background = 'black'
            vidToggle.innerHTML = "Video Off"
        }

        micToggle.onclick = () => {
            newVideo.muted = !newVideo.muted; // Toggle the mute state
            micToggle.innerHTML = newVideo.muted ? 'Mic Off' : 'Mic On'; // Update button text
        };

        vidToggle.onclick = () => {
            if (newVideo.style.visibility === 'visible') {
                newVideo.style.visibility = 'hidden'
                videoDiv.style.background = 'black'
            } else {
                newVideo.style.visibility = 'visible'
                videoDiv.style.background = 'transparent'
            }
            vidToggle.innerHTML = newVideo.style.visibility === 'visible' ? 'Video On' : 'Video Off'; // Update button text
        }

        videoDiv.appendChild(newVideo)
        videoDiv.appendChild(muteIcon);
        videoDiv.appendChild(name);
        videoDiv.appendChild(videoOff);
        vidCont.appendChild(videoDiv);
        // vidCont.appendChild(micToggle);
        // vidCont.appendChild(vidToggle);

        videoContainer.current.appendChild(vidCont);
    }

    const constraints = useMemo(() => ({
        audio: { echoCancellation: true },
        video: {
            width: { min: 400 },
            height: { min: 400 },
        },
    }), []);

    const myPeerConnection = useMemo(() => ({
        iceServers: [
            {
                urls: "stun:stun.relay.metered.ca:80",
            },
            {
                urls: "turn:standard.relay.metered.ca:80",
                username: "b5c002c77914b73c2fcb71d5",
                credential: "9L6MhO9ySdLIHcog",
            },
            {
                urls: "turn:standard.relay.metered.ca:80?transport=tcp",
                username: "b5c002c77914b73c2fcb71d5",
                credential: "9L6MhO9ySdLIHcog",
            },
            { url: 'stun:stun.l.google.com:19302' },
            {
                urls: "turn:standard.relay.metered.ca:443",
                username: "b5c002c77914b73c2fcb71d5",
                credential: "9L6MhO9ySdLIHcog",
            },
            {
                urls: "turn:standard.relay.metered.ca:443?transport=tcp",
                username: "b5c002c77914b73c2fcb71d5",
                credential: "9L6MhO9ySdLIHcog",
            },
            {
                urls: "turn:in.relay.metered.ca:80",
                username: "95f4e683500afaa2b3ea52e3",
                credential: "eyrkIoypovRn6WxW",
            },
            {
                urls: "turn:in.relay.metered.ca:80?transport=tcp",
                username: "95f4e683500afaa2b3ea52e3",
                credential: "eyrkIoypovRn6WxW",
            },
            {
                urls: "turn:in.relay.metered.ca:443",
                username: "95f4e683500afaa2b3ea52e3",
                credential: "eyrkIoypovRn6WxW",
            },
            {
                urls: "turn:in.relay.metered.ca:443?transport=tcp",
                username: "95f4e683500afaa2b3ea52e3",
                credential: "eyrkIoypovRn6WxW",
            },
        ],
    }), []);

    return (
        <>
            <MeetingContext.Provider value={{ constraints, myPeerConnection, createVideo }}>
                {props.children}
            </MeetingContext.Provider>
        </>
    )
}

export default MeetingState;