import * as React from "react";
import OAuth from '../components/OAuth';
import { useDispatch } from 'react-redux';
import http from '../utils/http'
import { SET_TOKEN, SET_USER } from '../store/auth';
import { useNavigate, useLocation } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const dispatch = useDispatch();
    const [form, setForm] = React.useState({ email: "", password: "" })

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        http.post('/api/login', form).then((res) => {
            if (res.data) {
                dispatch(SET_USER(res.data.data));
                dispatch(SET_TOKEN(res.data.token));
                navigate(location?.state?.prevUrl ? location?.state?.prevUrl : '/')
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    return (
        <>
            <div className="main">
                <div className="create-join my-3">
                    <div className="text-center my-5">
                        <span className="heading text-center">Sign In</span>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="mb-6">
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Your email</label>
                            <input type="email" id="email" name="email" onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 " required />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">Your password</label>
                            <input type="password" id="password" name="password" onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 " required />
                        </div>
                        <button type="submit" id="loginBtn" className="createroom-butt unselectable">Sign In</button>
                        <br />
                        <OAuth />
                    </form>

                </div>
            </div>
        </>
    )
}

export default Login;