import React, { useState, useRef, useEffect } from 'react';
import './Chat.css';

const Chat = ({ showChat, handleSendMessage, messages, userDetails, roomTotalUser }) => {
    const [inputValue, setInputValue] = useState('');
    const [chatOpen, setChatOpen] = useState(true);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    };

    const sendMessage = () => {
        if (inputValue.trim() !== '') {
            handleSendMessage(inputValue)
            setInputValue('');
        }
    };

    // Create a ref for the chat container
    const chatContainerRef = useRef();

    useEffect(() => {
        if (showChat && chatContainerRef.current && chatOpen) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [showChat, messages, chatOpen]);

    return showChat ? (
        <div className="page-container">
            <div className="right-cont container">
                <div className="head-title">
                    <div className={`chats ${chatOpen && "chat-border"}`} onClick={() => setChatOpen(true)}>Chats</div>
                    <div className={`attendies ${!chatOpen && "chat-border"}`} onClick={() => setChatOpen(false)}>Attendies <b id="total_count">{roomTotalUser}</b> </div>
                </div>
                <div className="chat-cont" ref={chatContainerRef}>
                    {chatOpen && messages.length > 0 && messages.map((message, index) => (
                        <div key={index} className="message">
                            <div className="message-text">
                                <span style={{ color: '#4ecca3', fontWeight: 'bold' }}>{message.senderName}: </span>
                                {message.time}
                                <br />
                                {message.msg}
                            </div>
                        </div>
                    ))}
                    {!chatOpen && Object.values(userDetails)?.length > 0 && Object.values(userDetails)?.map((user, index) => {
                        return <div key={index} className="message">
                            <div className="attendies-detail">
                                <span> {index + 1}: {user}</span>
                            </div>
                        </div>

                    })
                    }
                </div>

                {chatOpen && <div className="chat-input-cont">
                    <div className="ci-cont">
                        <input
                            type="text"
                            className="chat-input"
                            placeholder="Type chat here.."
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyDown={handleInputKeyDown}
                        />
                    </div>
                    <div className="ci-send">
                        <button className="chat-send" onClick={sendMessage}>
                            Send
                        </button>
                    </div>
                </div>}
            </div>
        </div >
    ) : null;
};

export default Chat;
