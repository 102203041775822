import * as React from "react";
import http from '../utils/http'
import { useNavigate } from "react-router-dom";

const CreateMeeting = () => {
    const navigate = useNavigate()
    const [form, setForm] = React.useState({ MeetingName: "", MeetingStartTime: "", MeetingEndTime: "" })

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        http.post('/api/createMeeting', form).then((res) => {
            if (res.data) {
                navigate('/meetingDetails')
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    return (
        <>
            <div className="main">
                <div className="create-join my-3">
                    <div className="text-center my-5">
                        <span className="heading text-center">Create Meeting</span>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-6">
                            <label htmlFor="MeetingName" className="block mb-2 text-sm font-medium text-gray-900 ">Meeting name</label>
                            <input type="text" id="MeetingName" name="MeetingName" onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5" required />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="MeetingStartTime" className="block mb-2 text-sm font-medium text-gray-900 ">Meeting Start Time</label>
                            <input type="datetime-local" id="MeetingStartTime" name="MeetingStartTime" onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5" required />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="MeetingEndTime" className="block mb-2 text-sm font-medium text-gray-900 ">Meeting End Time</label>
                            <input type="datetime-local" id="MeetingEndTime" name="MeetingEndTime" onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5" required />
                        </div>
                        <button type="submit" className="createroom-butt unselectable">Create</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default CreateMeeting;